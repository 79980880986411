<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="depositModal"
      :title="`${$t('Add Khazna Transaction')}`"
      :buttons-hidden="true"
    >
      <p class="text-blue-900 flex justify-center font-medium">{{$t('Today\'s Required Deposit')}}<span class="text-green font-semibold ml-2">{{ Number(depositedObj.requested_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }} {{$t('EGP')}}</span></p>
      <div v-for="(transaction, index) in transactions" :key="index">
        <div class="grid md:grid-cols-2 grid-cols-1 mt-10 gap-4">
          <div class="col-span-1">
            <vs-input v-validate="'required|min_value:1'" :placeholder="$t('Amount')" key="amount" :name="`amount-${index}`" v-model="transaction.amount" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has(`amount-${index}`)">{{ errors.first(`amount-${index}`) ? errors.first(`amount-${index}`).split(`-${index}`)[0] + errors.first(`amount-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="col-span-1">
            <datepicker :disabledDates="disabledDates" v-validate="'required'" :name="`deposit date-${index}`" :placeholder="$t('Deposit Date') + '*'"  v-model="transaction.deposit_date" class="w-full depositDate"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has(`deposit date-${index}`)">{{ errors.first(`deposit date-${index}`) ? errors.first(`deposit date-${index}`).split(`-${index}`)[0] + errors.first(`deposit date-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="md:col-span-2 grid-cols-1">
            <vs-input v-validate="'required'" :placeholder="$t('Reference Number')" key="reference number" :name="`reference number-${index}`" v-model="transaction.reference_number" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has(`reference number-${index}`)">{{ errors.first(`reference number-${index}`) ? errors.first(`reference number-${index}`).split(`-${index}`)[0] + errors.first(`reference number-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
        </div>
        <div class="sm:w-4/5 m-auto mt-10">
          <input type="file" :name="'file_' + index" @change="changeImage($event, index)" class="hidden" ref="fileInput" :id="'file_' + index">
          <div
            @click="$refs['fileInput'][index].click()"
            @drop="handleDrop"
            @dragover="handleDragover"
            @dragenter="handleDragover"
            class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
            <div v-if="transaction.image === ''">
              <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
              <span>{{$t('Drop File or')}} </span>
              <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
            </div>
            <div v-else>
              <img class="w-20 h-20" :src="transaction.image"/>
            </div>
          </div>
          <span class="text-danger text-sm" v-show="!transaction.image">This field is required</span>
        </div>
        <div class="relative flex items-center justify-end mt-8">
          <div class="absolute add-products">
            <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add transaction')}}</span>
          </div>
          <div class="p-1 flex self-center bg-primary rounded">
            <feather-icon @click="addTransaction(index)" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
          </div>
        </div>
      </div>
      <vs-alert icon-pack="feather" icon="icon-alert-circle" class="mt-2" active="true" color="warning" v-if="transactions.reduce((sum, transaction) => sum + Number(transaction.amount), 0) < depositedObj.requested_amount">
        {{ $t('Sum of shipping fees divided by the total orders in this month') }}
      </vs-alert>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="addKhaznaTransaction" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['depositModal', 'khazna', 'depositedObj'],
  data () {
    return {
      disabledDates: {
        from: new Date()
      },
      transactions: [
        {
          amount: '',
          by: '',
          reference_number: '',
          deposit_date: '',
          image: ''
        }
      ],
      userInfo: {}
    }
  },
  watch: {
    'depositModal' () {
      this.disabledDates = {
        from: new Date()
      }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    addTransaction (index) {
      this.$validator.validateAll().then(result => {
        if (result && this.transactions[index].image !== '') {
          this.transactions.push({
            amount: '',
            by: '',
            reference_number: '',
            deposit_date: '',
            image: ''
          })
        }
      })
    },
    addKhaznaTransaction () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          const transactions = []
          this.transactions.forEach(item => {
            transactions.push({
              deposited_amount: Number(item.amount),
              deposit_date: new Date(item.deposit_date).toISOString().split('T')[0],
              by: this.userInfo.name,
              reference_number: item.reference_number,
              deposit_slip_key: item.file_key
            })
          })
          sendRequest(false, false, this, `api/v1/accounting/khazna/${this.khazna.id}/withdraw-requests/${this.depositedObj.id}/complete/`, 'post', {transactions}, true,
            () => {
              this.$validator.pause()
              this.$vs.loading.close()
              this.closeModal()
            }
          )
        }
      })
    },
    closeModal () {
      this.$validator.pause()
      this.transactions = [
        {
          amount: '',
          by: '',
          reference_number: '',
          deposit_date: '',
          image: ''
        }
      ]
      this.$emit('depositModal', false)
      this.$emit('loadTransactions')
    },
    changeImage (event, index) {
      this.$vs.loading()
      const file = event.target.files[0]
      if (file) {
        this.transactions[index].image = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', file)
      sendRequest(false, false, this, 'api/v1/upload-bank-deposit-file/', 'post', formData, true,
        (response) => {
          this.transactions[index].file_key = response.data.file_key
        }
      )
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  },
  created () {
    this.userInfo = localStorage.getItem('idTokenPayload') ? JSON.parse(localStorage.getItem('idTokenPayload')) : {}
  },
  components: {
    ShipbluPrompt,
    vSelect,
    Datepicker
  }
}
</script>